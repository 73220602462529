// yandex maps api: https://yandex.ru/dev/maps/jsapi/doc/2.1/ref/concepts/About.html
import { h, Component, createRef } from 'preact';
import NoticeService from 'core/services/NoticeService';
import generateId from 'core/utils/generateId';
import { OPERATION_SET_YANDEX_PICKUP } from 'site/VControllers/components/Checkout/operations/yandex';
import initYmapScript from 'site/VControllers/components/Checkout/services/initYmapScript';
import getCityCenter from 'site/VControllers/components/Checkout/api/getCityCenter';
import getPoints from './api/getPoints';
import getPrice from './api/getPrice';
import getData from './utils/getData';
export class Yandex extends Component {
    constructor() {
        super(...arguments);
        this.mapId = `yandex_map_${generateId()}`;
        this.ymapContainerRef = createRef();
        this.showedPricePoints = new Set;
        this.state = {
            loading: false,
            chosenPickupAddress: null,
            pickupPointsList: null
        };
        this.initYmaps = () => {
            window.ymaps.ready(async () => {
                var _a;
                const pickupPointId = (_a = this.context.store.getState().delivery.yandexWidgetParams) === null || _a === void 0 ? void 0 : _a.pickupPointId;
                await this.setupMap();
                this.setupObjectManager();
                await this.updatePoints();
                if (pickupPointId) {
                    const object = this.objectManager.objects.getById(pickupPointId);
                    if (object) {
                        this.map.setCenter(object.geometry.coordinates, 16);
                        const objects = this.objectManager.objects;
                        await objects.balloon.open(pickupPointId);
                        this.setState({
                            chosenPickupAddress: object.properties.fullAddress
                        });
                        this.reSyncObjectPrice(object);
                    }
                }
            });
        };
        this.handleCityChange = async () => {
            const { delivery: { cityName, cityCoordinates, yandexMapApiKey } } = this.context.store.getState();
            if (cityCoordinates) {
                this.map.setCenter(cityCoordinates, 10);
            }
            else {
                const cityCenter = await getCityCenter(yandexMapApiKey, cityName);
                this.map.setCenter(cityCenter, 10);
            }
        };
        this.setupMap = async () => {
            const ymaps = window.ymaps;
            const { delivery: { yandexWidgetParams, cityName, cityCoordinates, yandexMapApiKey } } = this.context.store.getState();
            let center = [55.76, 37.64]; // default for moscow
            if (yandexWidgetParams === null || yandexWidgetParams === void 0 ? void 0 : yandexWidgetParams.pickupPointCoordinates) {
                center = yandexWidgetParams.pickupPointCoordinates;
            }
            else if (cityCoordinates) {
                center = cityCoordinates;
            }
            else {
                center = await getCityCenter(yandexMapApiKey, cityName);
            }
            this.map = new ymaps.Map(this.mapId, {
                center,
                zoom: 10,
                controls: ['zoomControl', 'geolocationControl', 'searchControl']
            }, {
                // @ts-ignore
                searchControlProvider: 'yandex#search',
                suppressMapOpenBlock: true
            });
            this.map.events.add('boundschange', this.updatePoints);
        };
        this.setupObjectManager = () => {
            const ymaps = window.ymaps;
            this.objectManager = new ymaps.ObjectManager({
                clusterize: true,
                gridSize: Env.version === 'desktop' ? 120 : 100
            });
            this.objectManager.objects.options.set('preset', 'islands#redDotIcon');
            this.objectManager.clusters.options.set('preset', 'islands#redClusterIcons');
            this.map.geoObjects.add(this.objectManager);
            this.objectManager.objects.events.add('balloonopen', this.updateBalloon);
        };
        this.updatePoints = async () => {
            this.updatePickupPointsList();
            if (this.state.loading)
                return;
            this.setState({ loading: true });
            const { points } = await getPoints(this.map.getBounds());
            const data = getData(points);
            this.objectManager.add(data);
            this.updatePickupPointsList();
            this.setState({ loading: false });
        };
        this.updatePickupPointsList = () => {
            // @ts-ignore
            const geoQuery = window.ymaps.geoQuery;
            const result = geoQuery(this.objectManager.objects).searchIntersect(this.map);
            result.then(() => {
                const list = [];
                result.each((item) => {
                    const object = result.get(result.indexOf(item));
                    const pickupPointId = object.properties.get('pickupPointId');
                    const fullAddress = object.properties.get('fullAddress');
                    if (list.length < 400) {
                        list.push({ pickupPointId, fullAddress });
                    }
                });
                this.setState({
                    pickupPointsList: list
                });
            });
        };
        this.updateBalloon = async (e) => {
            const objectId = e.get('objectId');
            const object = this.objectManager.objects.getById(objectId);
            if (!object)
                return;
            if (/Стоимость:/.test(object.properties.balloonContentBody))
                return;
            const { price, postings } = await getPrice(object.id);
            if (price === undefined || price === null) {
                this.setObjectPrice(object, price, postings);
                this.setObjectActionButtonDisabled(object);
                return;
            }
            this.setObjectPrice(object, price, postings);
            if (this.state.chosenPickupAddress === object.properties.fullAddress) {
                this.setObjectActionCheck(object);
            }
            else {
                this.setObjectActionButton(object, this.serializePointPayload(object, price, postings));
            }
        };
        this.setObjectActionButton = (object, payload) => {
            const pointData = JSON.stringify(payload);
            object.properties.balloonContentFooter = `<button class='b-Ymap_actionButton' type='button' data-point-data='${pointData}'>Выбрать</button>`;
            this.setBalloonData(object);
        };
        this.setObjectActionButtonDisabled = (object) => {
            object.properties.balloonContentFooter = `<button class='b-Ymap_actionButton' type='button' disabled>Выбрать</button>`;
            this.setBalloonData(object);
        };
        this.setObjectActionCheck = (object) => {
            object.properties.balloonContentFooter = `<div class='b-Ymap_statusCheck'></div>`;
            this.setBalloonData(object);
        };
        this.setBalloonData = (object) => {
            const objects = this.objectManager.objects;
            objects.balloon.setData(object);
        };
        this.reSyncObjectPrice = async (object) => {
            this.removeObjectPrice(object);
            const objects = this.objectManager.objects;
            if (objects.balloon._objectIdWithOpenBalloon !== object.id)
                return;
            // ballon renewing
            const { price, postings } = await getPrice(object.id);
            this.setObjectPrice(object, price, postings);
            const payload = this.serializePointPayload(object, price, postings);
            if (this.state.chosenPickupAddress === object.properties.fullAddress) {
                // auto reset point
                this.context.store.operate(OPERATION_SET_YANDEX_PICKUP, payload).catch(() => {
                    this.setObjectActionButton(object, payload);
                });
            }
            else {
                this.setObjectActionButton(object, payload);
            }
        };
        this.setObjectPrice = (object, price, postings) => {
            object.price = price;
            if (postings)
                object.postings = postings;
            object.properties.balloonContentBody += `<p>Стоимость: ${price} руб.</p>`;
            const objects = this.objectManager.objects;
            this.showedPricePoints.add(object.id);
            objects.balloon.setData(object);
        };
        this.handleMapClick = (e) => {
            var _a;
            const target = e.target;
            const rawData = target.dataset.pointData;
            if (!rawData)
                return;
            const pickupPointId = (_a = this.context.store.getState().delivery.yandexWidgetParams) === null || _a === void 0 ? void 0 : _a.pickupPointId;
            if (pickupPointId) {
                const prevChosenObject = this.objectManager.objects.getById(pickupPointId);
                if (prevChosenObject && prevChosenObject.price) {
                    this.setObjectActionButton(prevChosenObject, this.serializePointPayload(prevChosenObject));
                }
            }
            const data = JSON.parse(rawData);
            const object = this.objectManager.objects.getById(data.id);
            if (!object)
                return;
            this.setObjectActionButtonDisabled(object);
            this.context.store
                .operate(OPERATION_SET_YANDEX_PICKUP, data)
                .then(() => {
                this.setObjectActionCheck(object);
                this.setState({
                    chosenPickupAddress: object.properties.fullAddress
                });
                NoticeService.info('Пункт выдачи выбран');
            });
        };
        this.handlePickupPointsListItemClick = (pickupPointId) => () => {
            const object = this.objectManager.objects.getById(pickupPointId);
            if (!object)
                return;
            this.map.setCenter(object.geometry.coordinates, 16);
            const objects = this.objectManager.objects;
            objects.balloon.open(pickupPointId);
        };
        this.removeObjectPrice = (object) => {
            object.price = undefined;
            object.postings = undefined;
            this.showedPricePoints.delete(object.id);
            object.properties.balloonContentBody = object.properties.balloonContentBody.replace(/<p>Стоимость: \d+ руб.<\/p>/, '');
        };
        this.serializePointPayload = (object, price, postings) => ({
            id: object.id,
            coordinates: object.geometry.coordinates,
            address: object.properties.fullAddress,
            postings: postings || object.postings,
            price: (price !== null && price !== void 0 ? price : object.price)
        });
    }
    componentDidMount() {
        const { delivery: { yandexMapApiKey } } = this.context.store.getState();
        initYmapScript(yandexMapApiKey, this.initYmaps);
        this.ymapContainerRef.current.addEventListener('click', this.handleMapClick);
    }
    componentDidUpdate(_previousProps, _previousState, previousContext) {
        const { yandexWidgetParams: newParams, postings: newPostings, cityName: newCity } = this.context.currentState.delivery;
        const { yandexWidgetParams: oldParams, postings: oldPostings, cityName: oldCity } = previousContext.currentState.delivery;
        if (newCity !== oldCity) {
            this.handleCityChange();
        }
        const newPostingSize = Object.keys(newPostings !== null && newPostings !== void 0 ? newPostings : {}).length;
        const isPostingChanged = Object.keys(oldPostings !== null && oldPostings !== void 0 ? oldPostings : {}).length !== newPostingSize;
        const isPointRemoved = (oldParams === null || oldParams === void 0 ? void 0 : oldParams.pickupPointId) && !(newParams === null || newParams === void 0 ? void 0 : newParams.pickupPointId);
        // reset map if posting is empty to avoid wrong money info
        if (newPostingSize === 0 && isPointRemoved) {
            this.setState({ chosenPickupAddress: null }, () => {
                var _a, _b;
                this.updatePoints();
                const object = this.objectManager.objects.getById(oldParams === null || oldParams === void 0 ? void 0 : oldParams.pickupPointId);
                if (object)
                    this.setObjectActionButton(object, this.serializePointPayload(object, object.price));
                // @ts-ignore
                (_b = (_a = this.objectManager.objects) === null || _a === void 0 ? void 0 : _a.balloon) === null || _b === void 0 ? void 0 : _b.close();
            });
        }
        else if ((isPostingChanged || isPointRemoved) && this.showedPricePoints.size > 0) {
            this.showedPricePoints.forEach(pointId => {
                const object = this.objectManager.objects.getById(pointId);
                if (object)
                    this.reSyncObjectPrice(object);
            });
        }
    }
    componentWillUnmount() {
        var _a;
        this.ymapContainerRef.current.removeEventListener('click', this.handleMapClick);
        // clear price state for postings
        if (Object.keys((_a = this.context.currentState.delivery.postings) !== null && _a !== void 0 ? _a : {}).length === 0)
            return;
        this.showedPricePoints.forEach(pointId => {
            const object = this.objectManager.objects.getById(pointId);
            if (object)
                this.removeObjectPrice(object);
        });
    }
    render() {
        const { chosenPickupAddress, pickupPointsList } = this.state;
        const { delivery: { yandexWidgetParams } } = this.context.store.getState();
        if (Env.name === 'test')
            return h("div", null);
        return (h("div", { className: 'b-Ymap_withAsideContainer' },
            h("div", { className: 'b-Ymap_map', ref: this.ymapContainerRef, id: this.mapId }),
            h("div", { className: 'b-Ymap_aside' },
                h("p", { className: 'b-Ymap_asideTitle' }, "\u0412\u044B\u0431\u043E\u0440 \u043F\u0443\u043D\u043A\u0442\u0430 \u0432\u044B\u0434\u0430\u0447\u0438"),
                chosenPickupAddress && (h("p", { className: 'b-Ymap_chosenPickupAddress' }, chosenPickupAddress)),
                pickupPointsList && (h("ul", null, pickupPointsList.map(({ fullAddress, pickupPointId }, index) => {
                    if (pickupPointId === (yandexWidgetParams === null || yandexWidgetParams === void 0 ? void 0 : yandexWidgetParams.pickupPointId))
                        return null;
                    return (h("li", { key: index, onClick: this.handlePickupPointsListItemClick(pickupPointId) }, fullAddress));
                }))))));
    }
}
