import { h, Component } from 'preact';
// @ts-ignore
import isEqual from 'lodash/isEqual';
// @ts-ignore
import debounce from 'lodash/debounce';
import { uuidV4 } from 'core/utils/uuid';
import { makeRequest } from './request';
import { DefaultHttpCache } from './http-cache';
export class BaseSuggestions extends Component {
    constructor(props) {
        super(props);
        this.loadSuggestionsUrl = '';
        this.dontPerformBlurHandler = false;
        this.getSuggestionsUrl = () => {
            return this.loadSuggestionsUrl;
        };
        this.setupDebounce = (delay) => {
            if (typeof delay === 'number' && delay > 0) {
                this.fetchSuggestions = debounce(this.performFetchSuggestions, delay);
            }
            else {
                this.fetchSuggestions = this.performFetchSuggestions;
            }
        };
        this.fetchSuggestions = () => {
            //
        };
        this.clear = () => {
            this.setState({ query: '', inputQuery: '' });
        };
        this.handleInputFocus = (event) => {
            this.setState({ isFocused: true });
            const { suggestions } = this.state;
            if (suggestions.length === 0) {
                this.fetchSuggestions();
            }
            const { inputProps } = this.props;
            if (inputProps && inputProps.onFocus) {
                inputProps.onFocus(event);
            }
        };
        this.handleInputBlur = (event) => {
            const { suggestions, suggestionIndex } = this.state;
            const { selectOnBlur, inputProps } = this.props;
            this.setState({ isFocused: false });
            if (suggestions.length === 0) {
                this.fetchSuggestions();
            }
            if (selectOnBlur && !this.dontPerformBlurHandler) {
                if (suggestions.length > 0) {
                    const suggestionIndexToSelect = suggestionIndex >= 0 && suggestionIndex < suggestions.length ? suggestionIndex : 0;
                    this.selectSuggestion(suggestionIndexToSelect, true);
                }
            }
            this.dontPerformBlurHandler = false;
            if (inputProps && inputProps.onBlur) {
                inputProps.onBlur(event);
            }
        };
        this.handleInputChange = (event) => {
            const value = event.target.value;
            const { inputProps } = this.props;
            if (this.didMount) {
                this.setState({ query: value, inputQuery: value, displaySuggestions: !!value }, () => {
                    this.fetchSuggestions();
                });
            }
            if (inputProps && inputProps.onChange) {
                inputProps.onChange(event);
            }
        };
        this.handleInputKeyDown = (event) => {
            this.handleKeyboard(event);
            const { inputProps } = this.props;
            if (inputProps && inputProps.onKeyDown) {
                inputProps.onKeyDown(event);
            }
        };
        this.handleInputKeyPress = (event) => {
            this.handleKeyboard(event);
            const { inputProps } = this.props;
            if (inputProps && inputProps.onKeyPress) {
                inputProps.onKeyPress(event);
            }
        };
        this.handleKeyboard = (event) => {
            const { suggestions, suggestionIndex, inputQuery } = this.state;
            if (event.which === 40) {
                // Arrow down
                event.preventDefault();
                if (suggestionIndex < suggestions.length - 1) {
                    const newSuggestionIndex = suggestionIndex + 1;
                    const newInputQuery = this.getSuggestionText(suggestions[newSuggestionIndex]);
                    if (this.didMount) {
                        this.setState({ suggestionIndex: newSuggestionIndex, query: newInputQuery });
                    }
                }
            }
            else if (event.which === 38) {
                // Arrow up
                event.preventDefault();
                if (suggestionIndex >= 0) {
                    const newSuggestionIndex = suggestionIndex - 1;
                    const newInputQuery = newSuggestionIndex === -1 ? inputQuery : this.getSuggestionText(suggestions[newSuggestionIndex]);
                    if (this.didMount) {
                        this.setState({ suggestionIndex: newSuggestionIndex, query: newInputQuery });
                    }
                }
            }
            else if (event.which === 13) {
                // Enter
                event.preventDefault();
                if (suggestionIndex >= 0) {
                    this.selectSuggestion(suggestionIndex);
                }
            }
        };
        this.performFetchSuggestions = () => {
            const { minChars, token } = this.props;
            const { query } = this.state;
            if (typeof minChars === 'number' && minChars > 0 && query.length < minChars) {
                this.setState({ suggestions: [], suggestionIndex: -1 });
                return;
            }
            makeRequest('POST', this.getSuggestionsUrl(), {
                headers: {
                    Accept: 'application/json',
                    Authorization: `Token ${token}`,
                    'Content-Type': 'application/json'
                },
                json: this.getLoadSuggestionsData()
            }, this.httpCache, (suggestions) => {
                suggestions = this.filterSuggestionsBySettlement(suggestions);
                if (this.didMount) {
                    if (suggestions.length === 0 && query.length > 9 && query.indexOf('кв') > 0) {
                        // оставляем старые значения
                        this.setState({ suggestionIndex: -1 });
                    }
                    else {
                        this.setState({ suggestions, suggestionIndex: -1 });
                    }
                }
            });
        };
        this.filterSuggestionsBySettlement = (suggestions) => {
            const { settlementQuery } = this.props;
            const { query } = this.state;
            if (!settlementQuery)
                return suggestions;
            const fullQuery = [settlementQuery, query].join(', ');
            return suggestions.filter(suggestion => (
            // .length + 1 is because user can type address without comma
            suggestion.value.split(',').length <= fullQuery.split(',').length + 1));
        };
        this.onSuggestionClick = (index, event) => {
            event.stopPropagation();
            this.selectSuggestion(index);
        };
        this.selectSuggestion = (index, isSilent = false) => {
            const { suggestions } = this.state;
            const { selectOnBlur, onChange } = this.props;
            if (suggestions.length >= index - 1) {
                const suggestion = suggestions[index];
                if (selectOnBlur) {
                    this.dontPerformBlurHandler = true;
                }
                const suggestionText = this.getSuggestionText(suggestion);
                this.setState({ query: suggestionText, inputQuery: suggestionText, displaySuggestions: false }, () => {
                    if (!isSilent) {
                        this.fetchSuggestions();
                        setTimeout(() => this.setCursorToEnd(this.textInput));
                    }
                });
                if (onChange) {
                    onChange(suggestion);
                }
            }
        };
        this.setCursorToEnd = (element) => {
            if (element) {
                const valueLength = element.value.length;
                if (element.selectionStart || element.selectionStart === 0) {
                    element.selectionStart = valueLength;
                    element.selectionEnd = valueLength;
                    element.focus();
                }
            }
        };
        this.getHighlightWords = () => {
            const { inputQuery } = this.state;
            const wordsToPass = ['г', 'респ', 'ул', 'р-н', 'село', 'деревня', 'поселок', 'пр-д', 'пл', 'к', 'кв', 'обл', 'д'];
            let words = inputQuery.replace(',', '').split(' ');
            words = words.filter((word) => {
                return wordsToPass.indexOf(word) < 0;
            });
            return words;
        };
        this.getSuggestionKey = (suggestion) => suggestion.value;
        this.focus = () => {
            if (this.textInput) {
                this.textInput.focus();
            }
        };
        this.setInputValue = (value) => {
            this.setState({ query: value || '', inputQuery: value || '' });
        };
        this.didMount = false;
        const { defaultQuery, value, delay } = this.props;
        const valueQuery = value ? value.value : undefined;
        this.setupDebounce(delay);
        this.state = {
            query: defaultQuery || valueQuery || '',
            inputQuery: defaultQuery || valueQuery || '',
            isFocused: false,
            displaySuggestions: true,
            suggestions: [],
            suggestionIndex: -1
        };
    }
    componentDidMount() {
        this.didMount = true;
    }
    componentDidUpdate(prevProps) {
        const { value, delay } = this.props;
        const { query, inputQuery } = this.state;
        if (!isEqual(prevProps.value, value)) {
            const newQuery = value ? value.value : '';
            if (query !== newQuery || inputQuery !== newQuery) {
                this.setState({ query: newQuery, inputQuery: newQuery });
            }
        }
        if (delay !== prevProps.delay) {
            this.setupDebounce(delay);
        }
    }
    componentWillUnmount() {
        this.didMount = false;
    }
    get uid() {
        if (!this._uid) {
            this._uid = uuidV4();
        }
        return this._uid;
    }
    get httpCache() {
        const { httpCache: cacheProp, httpCacheTtl: ttl } = this.props;
        if (!cacheProp) {
            return null;
        }
        const cache = DefaultHttpCache.shared;
        if (typeof ttl === 'number') {
            cache.ttl = ttl;
        }
        return cache;
    }
    render() {
        const { inputProps, hintText, labelText, containerClassName, hintClassName, suggestionsClassName, suggestionClassName, currentSuggestionClassName, customInput, children } = this.props;
        const { isFocused, query, suggestions, suggestionIndex, displaySuggestions } = this.state;
        const Component = typeof customInput !== 'undefined' ? customInput : 'input';
        const optionsExpanded = isFocused && suggestions && displaySuggestions && suggestions.length > 0;
        return (h("div", { role: 'combobox', "aria-expanded": optionsExpanded ? 'true' : 'false', "aria-owns": this.uid, "aria-controls": this.uid, "aria-haspopup": 'listbox', className: containerClassName || 'react-dadata react-dadata__container' },
            h("div", null,
                typeof labelText !== 'undefined' && (h("label", { class: 'ui-Input_label' },
                    labelText,
                    h("abbr", { title: '\u041E\u0431\u044F\u0437\u0430\u0442\u0435\u043B\u044C\u043D\u043E' }, "*"))),
                h(Component, Object.assign({ autoComplete: 'off', className: 'ui-Input_input' }, inputProps, { value: query, ref: (input) => {
                        this.textInput = input;
                    }, onInput: this.handleInputChange, onKeyPress: this.handleInputKeyPress, onKeyDown: this.handleInputKeyDown, onFocus: this.handleInputFocus, onBlur: this.handleInputBlur })),
                query && (h("div", { className: 'ui-Input_autocompleteActions __visible', style: { right: 0, opacity: (isFocused ? 1 : 0) } },
                    h("div", { className: 'ui-Input_autocompleteClear', onClick: this.clear },
                        h("span", null))))),
            optionsExpanded && (h("ul", { id: this.uid, "aria-expanded": true, role: 'listbox', className: suggestionsClassName || 'react-dadata__suggestions' },
                typeof hintText !== 'undefined' && (h("div", { className: hintClassName || 'react-dadata__suggestion-note' }, hintText)),
                suggestions.map((suggestion, index) => {
                    let suggestionClass = suggestionClassName || 'react-dadata__suggestion';
                    if (index === suggestionIndex) {
                        suggestionClass += ` ${currentSuggestionClassName || 'react-dadata__suggestion--current'}`;
                    }
                    return (h("li", { role: 'option', "aria-selected": index === suggestionIndex ? 'true' : 'false', key: this.getSuggestionKey(suggestion), onMouseDown: this.onSuggestionClick.bind(this, index), className: suggestionClass }, this.renderOption(suggestion)));
                }))),
            children));
    }
}
